import React from 'react'
import img1 from "../../Assets/ProductCard/1.png";
import img2 from "../../Assets/ProductCard/2.png";
import img3 from "../../Assets/ProductCard/3.png";
import img4 from "../../Assets/ProductCard/4.png";
import img5 from "../../Assets/ProductCard/500mlWater.png";
import img6 from "../../Assets/ProductCard/5LWater.png";

import fresh from '../../Assets/Home/fresh.png'
import soft from '../../Assets/Home/soft.png'
import { FaStar } from "react-icons/fa6";

const ProductCards = () => {
  return (
    <React.Fragment>
      <div className="px-6 md:px-16 mt-8 mb-8">
        <div className="flex flex-col md:flex-row justify-between">
          <div className="mb-4 md:mb-0">
            <h3 className="text-xl md:text-2xl text-[#F9B401]">Products</h3>
            <h1 className="text-3xl md:text-5xl mt-2 font-bold">Fresh Drinking Water</h1>
          </div>
          <div className="text-lg md:text-xl">
            <button className="px-4 md:px-6 py-2 bg-[#F9B401] rounded-lg">View All</button>
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8 mt-6">
          <div className="bg-white shadow-md rounded-lg overflow-hidden relative hover:shadow-lg transition-shadow duration-300 ease-in-out">
            <div className="relative w-full">
              <div className="w-full bg-[#f6f6f6] rounded-lg overflow-hidden">
                <img
                  src={fresh}
                  alt={img1}
                  className="w-full object-contain transform transition-transform duration-300 hover:scale-105"
                />
              </div>
            </div>
          </div>
          <div className="bg-white shadow-md rounded-lg overflow-hidden relative p-4 hover:shadow-lg transition-shadow duration-300 ease-in-out">
            <div className="relative w-full">
              <div className="w-full bg-[#f6f6f6] rounded-lg overflow-hidden">
                <img
                  src={img5}
                  alt={img5}
                  className="w-full h-80 object-contain transform transition-transform duration-300 hover:scale-105" // Increased height to 80
                />
              </div>
              <div className="absolute top-2 right-2 bg-[#e38b8b] text-white text-xs font-semibold px-2 py-1 rounded-lg">
                500 Ml
              </div>
            </div>
            <div className="pt-4 text-center">
              <p className="flex justify-center items-center space-x-1 text-[#F5A93F] mb-2">
                {[...Array(5)].map((_, i) => (
                  <FaStar key={i} size={14} />
                ))}
              </p>
              <h2 className="text-md tracking-wide font-semibold">
                Premium water (Mini)
              </h2>
            </div>
          </div>

          <div className="bg-white shadow-md rounded-lg overflow-hidden relative p-4 hover:shadow-lg transition-shadow duration-300 ease-in-out">
            <div className="relative w-full">
              <div className="w-full bg-[#f6f6f6] rounded-lg overflow-hidden">
                <img
                  src={img6}
                  alt={img6}
                  className="w-full h-80 object-contain transform transition-transform duration-300 hover:scale-105" // Increased height to 80
                />
              </div>
              <div className="absolute top-2 right-2 bg-[#e38b8b] text-white text-xs font-semibold px-2 py-1 rounded-lg">
                5 L
              </div>
            </div>

            <div className="pt-4 text-center">
              <p className="flex justify-center items-center space-x-1 text-[#F5A93F] mb-2">
                {[...Array(5)].map((_, i) => (
                  <FaStar key={i} size={14} />
                ))}
              </p>
              <h2 className="text-md tracking-wide font-semibold">
                Premium water (Large)
              </h2>
            </div>
          </div>

          <div className="bg-white shadow-md rounded-lg overflow-hidden relative p-4 hover:shadow-lg transition-shadow duration-300 ease-in-out">
            <div className="relative w-full">
              <div className="w-full bg-[#f6f6f6] rounded-lg overflow-hidden">
                <img
                  src={img1}
                  alt={img1}
                  className="w-full h-80 object-contain transform transition-transform duration-300 hover:scale-105" // Increased height to 80
                />
              </div>
              <div className="absolute top-2 right-2 bg-[#e38b8b] text-white text-xs font-semibold px-2 py-1 rounded-lg">
                1 L
              </div>
            </div>

            <div className="pt-4 text-center">
              <p className="flex justify-center items-center space-x-1 text-[#F5A93F] mb-2">
                {[...Array(5)].map((_, i) => (
                  <FaStar key={i} size={14} />
                ))}
              </p>
              <h2 className="text-md tracking-wide font-semibold">
                Premium water (Regular)
              </h2>
            </div>
          </div>
        </div>
      </div>

      {/* Tasty Soft Drinks */}
      <div className="px-6 md:px-16 mt-8 mb-8">
        <div className="flex flex-col md:flex-row justify-between">
          <div className="mb-4 md:mb-0">
            <h3 className="text-xl md:text-2xl text-[#F9B401]">Products</h3>
            <h1 className="text-3xl md:text-5xl mt-2 font-bold">Tasty Soft Drinks</h1>
          </div>
          <div className="text-lg md:text-xl">
            <button className="px-4 md:px-6 py-2 bg-[#F9B401] rounded-lg">View All</button>
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8 mt-6">
          <div className="bg-white shadow-md rounded-lg overflow-hidden relative hover:shadow-lg transition-shadow duration-300 ease-in-out">
            <div className="relative w-full">
              <div className="w-full bg-[#f6f6f6] rounded-lg overflow-hidden">
                <img
                  src={soft}
                  alt={img2}
                  className="w-full object-contain transform transition-transform duration-300 hover:scale-105"
                />
              </div>
            </div>
          </div>
          <div className="bg-white shadow-md rounded-lg overflow-hidden relative p-4 hover:shadow-lg transition-shadow duration-300 ease-in-out">
            <div className="relative w-full">
              <div className="w-full bg-[#f6f6f6] rounded-lg overflow-hidden">
                <img
                  src={img3}
                  alt={img3}
                  className="w-full h-80 object-contain transform transition-transform duration-300 hover:scale-105" // Increased height to 80
                />
              </div>
              <div className="absolute top-2 right-2 bg-[#e38b8b] text-white text-xs font-semibold px-2 py-1 rounded-lg">
                250 Ml
              </div>
            </div>
            <div className="pt-4 text-center">
              <p className="flex justify-center items-center space-x-1 text-[#F5A93F] mb-2">
                {[...Array(5)].map((_, i) => (
                  <FaStar key={i} size={14} />
                ))}
              </p>
              <h2 className="text-md tracking-wide font-semibold">
                Ziyo Cloudy
              </h2>
            </div>
          </div>

          <div className="bg-white shadow-md rounded-lg overflow-hidden relative p-4 hover:shadow-lg transition-shadow duration-300 ease-in-out">
            <div className="relative w-full">
              <div className="w-full bg-[#f6f6f6] rounded-lg overflow-hidden">
                <img
                  src={img4}
                  alt={img4}
                  className="w-full h-80 object-contain transform transition-transform duration-300 hover:scale-105" // Increased height to 80
                />
              </div>
              <div className="absolute top-2 right-2 bg-[#e38b8b] text-white text-xs font-semibold px-2 py-1 rounded-lg">
                250 Ml
              </div>
            </div>
            <div className="pt-4 text-center">
              <p className="flex justify-center items-center space-x-1 text-[#F5A93F] mb-2">
                {[...Array(5)].map((_, i) => (
                  <FaStar key={i} size={14} />
                ))}
              </p>
              <h2 className="text-md tracking-wide font-semibold">
                Mango Juice
              </h2>
            </div>
          </div>

          <div className="bg-white shadow-md rounded-lg overflow-hidden relative p-4 hover:shadow-lg transition-shadow duration-300 ease-in-out">
            <div className="relative w-full">
              <div className="w-full bg-[#f6f6f6] rounded-lg overflow-hidden">
                <img
                  src={img2}
                  alt={img2}
                  className="w-full h-80 object-contain transform transition-transform duration-300 hover:scale-105" // Increased height to 80
                />
              </div>
              <div className="absolute top-2 right-2 bg-[#e38b8b] text-white text-xs font-semibold px-2 py-1 rounded-lg">
                250 Ml
              </div>
            </div>
            <div className="pt-4 text-center">
              <p className="flex justify-center items-center space-x-1 text-[#F5A93F] mb-2">
                {[...Array(5)].map((_, i) => (
                  <FaStar key={i} size={14} />
                ))}
              </p>
              <h2 className="text-md tracking-wide font-semibold">
                Ziyo Zeera
              </h2>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ProductCards