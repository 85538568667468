import React from 'react'
import img2 from '../../Assets/Aboutus/Frame 427321511.png'
import img4 from '../../Assets/Aboutus/Frame 427321512.png'
import img3 from '../../Assets/Aboutus/Frame 427321513.png'

const Founder = () => {
    return (
        <div className='space-y-6 mt-6 px-4 md:px-16'>
            <img 
                src={img2} 
                alt='chairman Img' 
                className='w-full object-cover'
            />
            <img 
                src={img4} 
                alt='Founder Img' 
                className='w-full object-cover'
            />
            <img 
                src={img3} 
                alt='director Img' 
                className='w-full object-cover'
            />
        </div>
    )
}

export default Founder

