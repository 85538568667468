import React from 'react';
import img from '../../Assets/Aboutus/nature.png';

const Product = () => {
  return (
    <div className='px-4 md:px-8 lg:px-16 flex flex-col md:flex-row gap-8 md:gap-14'>
      <div className='w-full md:w-[60%] rounded-md'>
        <iframe
          width="100%"
          height="300"
          className='md:h-[400px] lg:h-[500px] rounded-md'
          src="https://www.youtube.com/embed/WqhwEEk3MrY?si=bGPgUElvdcu8eG3-"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        />
      </div>
      <div className='w-full md:w-[40%] flex justify-center rounded-md'>
        <img src={img} alt='img' className='w-[80%] md:w-[95%] rounded-md' />
      </div>
    </div>
  );
}

export default Product;
