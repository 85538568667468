import React from 'react';
import blog1 from '../../Assets/Home/blog1.png'; // Image 1
import blog2 from '../../Assets/Home/blog2.png'; // Image 2
import blog3 from '../../Assets/Home/blog3.png'; // Image 3
import { CgProfile } from "react-icons/cg";
import { CiCalendar } from "react-icons/ci";
import { AiOutlineMessage } from "react-icons/ai";
import { HiOutlineArrowSmRight } from "react-icons/hi";

const NewsAndBlogs = () => {
  const articles = [
    {
      img: blog1,
      author: "Bibek Swain",
      date: "25 Sep, 2024",
      comments: "10 Comments",
      title: "Exploring the Latest Trends in Tech.",
      description: "The tech industry is evolving rapidly. Discover the latest trends and innovations that are reshaping our world."
    },
    {
      img: blog2,
      author: "Abhishek",
      date: "20 Sep, 2024",
      comments: "5 Comments",
      title: "Healthy Living: Tips for a Balanced Diet.",
      description: "Learn how to maintain a balanced diet with our top tips for healthy eating and lifestyle choices."
    },
    {
      img: blog3,
      author: "Satya Biswal",
      date: "15 Sep, 2024",
      comments: "8 Comments",
      title: "Travel Destinations You Can't Miss in 2024.",
      description: "Plan your next adventure with our list of must-visit travel destinations for 2024."
    }
  ];

  return (
    <React.Fragment>
      <h3 className='text-center text-[#F9B401] mt-6'>News</h3>
      <h1 className='text-3xl md:text-5xl mt-2 font-bold text-center mb-4'>Latest News & Blogs</h1>
      <div className='mb-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-7 px-2 sm:px-4 lg:px-16 mt-4'>
        {articles.map((article, index) => (
          <div key={index} className='p-2 sm:p-3 lg:p-5 border-4 rounded-lg shadow-lg transition-transform transform hover:scale-105 hover:shadow-xl'>
            <div className='flex items-center justify-center'>
              <img src={article.img} alt='img' className='w-full h-auto rounded-lg p-2 sm:p-3' />
            </div>
            <div className='space-y-4 p-2 sm:p-3 lg:p-5'>
              <div className='flex flex-wrap space-x-4 text-[#F5A93F]'>
                <div className='flex space-x-2 items-center'><CgProfile /><span className='text-black'>{article.author}</span></div>
                <div className='flex space-x-2 items-center'><CiCalendar /><span className='text-black'>{article.date}</span></div>
                <div className='flex space-x-2 items-center'><AiOutlineMessage /><span className='text-black'>{article.comments}</span></div>
              </div>
              <div>
                <h1 className='text-lg sm:text-xl font-semibold'>{article.title}</h1>
              </div>
              <div>
                <p className='text-sm sm:text-base text-gray-700'>{article.description}</p>
              </div>
              <button className='flex items-center justify-center border border-[#F5A93F] p-2 space-x-2 rounded-lg text-[#F5A93F] hover:bg-[#F5A93F] hover:text-white transition-colors'>
                Read more<span><HiOutlineArrowSmRight /></span>
              </button>
            </div>
          </div>
        ))}
      </div>
    </React.Fragment>
  );
}

export default NewsAndBlogs;
