import React from 'react'
import { BiPhoneCall } from "react-icons/bi";
import { MdOutlineShareLocation } from "react-icons/md";
import { BiMailSend } from "react-icons/bi";
const Map = () => {
    return (
        <>
            <div className='px-4 sm:px-6 lg:px-8 flex items-center justify-center mt-8'>
                <h1 className='mx-auto text-2xl sm:text-3xl lg:text-5xl text-center'>
                    Questions? Feel Free to Reach Out
                    <br />
                    <span className='block lg:inline'>
                        via Message or Call
                    </span>
                </h1>
            </div>

            <div className="flex flex-col lg:flex-row justify-center gap-8 px-6 md:px-16 mt-8">
                <div className="flex flex-col gap-6 lg:w-1/3">
                    <div className="p-6 bg-white rounded-lg flex items-center">
                        <BiMailSend size={44} className="text-[#F9CE06] mr-4" />
                        <div>
                            <h4 className="text-xl tracking-wider font-semibold text-primaryColor">Email Address</h4>
                            <p className="text-sm text-secondaryTextColor tracking-wide mt-2">
                                baisalyaqua@gmail.com
                            </p>
                        </div>
                    </div>
                    <div className="p-6 bg-white rounded-lg flex items-center">
                        <MdOutlineShareLocation size={44} className="text-[#F9CE06] mr-4" />
                        <div>
                            <h4 className="text-xl tracking-wider font-semibold text-primaryColor">Office Address</h4>
                            <p className="text-sm text-secondaryTextColor tracking-wide mt-2">
                                Samaleswari Aqua Industries, At Chancher-766011, Post-Utkela, Dist-Kalahandi, Odisha
                            </p>
                        </div>
                    </div>
                    <div className="p-6 bg-white rounded-lg flex items-center">
                        <BiPhoneCall size={44} className="text-[#F9CE06] mr-4" />
                        <div>
                            <h4 className="text-xl tracking-wider font-semibold text-primaryColor">Phone Numbers</h4>
                            <p className="text-sm text-secondaryTextColor tracking-wide mt-2">
                                06670-231347
                            </p>
                        </div>
                    </div>
                </div>

                <div className="flex-grow rounded-lg overflow-hidden h-80 lg:h-auto lg:w-2/3">
                    <iframe
                        title="Google Map"
                        className="w-full h-full"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d77762.09971854422!2d83.16434739509329!3d20.064114049825204!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a24f3e7491dd471%3A0xa466f64dca57cccb!2sSamaleswari%20Aqua%20Industries!5e0!3m2!1sen!2sin!4v1728385770365!5m2!1sen!2sin"
                        allowFullScreen=""
                        loading="lazy"
                    />
                </div>
            </div>
        </>

    )
}

export default Map