import React, { useState, useEffect } from 'react';
import img1 from "../../Assets/ProductCard/1.png";
import img2 from "../../Assets/ProductCard/2.png";
import img3 from "../../Assets/ProductCard/3.png";
import img4 from "../../Assets/ProductCard/4.png";
import { FaStar } from "react-icons/fa6";

const products = [
  {
    image: img1,
    name: "Premium water",
    volume: "1 L",
    price: "₹20",
    rating: 4.5,
  },
  {
    image: img2,
    name: "Ziyo Zeera",
    volume: "250 ml",
    price: "₹12.00",
    rating: 4.7,
  },
  {
    image: img3,
    name: "Ziyo Cloudy",
    volume: "250 ml",
    price: "₹12.00",
    rating: 4.2,
  },
  {
    image: img4,
    name: "Mango Juice",
    volume: "250 ml",
    price: "₹12.00",
    rating: 4.1,
  },
];

const ProductCards = () => {
  // Countdown logic
  const calculateTimeLeft = () => {
    const targetDate = new Date('2024-12-31T23:59:59'); // Set your target date and time
    const now = new Date();
    const difference = targetDate - now;

    let timeLeft = {};
    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      // If the target date has passed, return zeros
      timeLeft = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }
    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer); // Cleanup the interval on unmount
  }, []);

  // Helper function to format the time values
  const formatTime = (value) => {
    return value.toString().padStart(2, '0'); // Add leading zero if needed
  };

  return (
    <React.Fragment>
      {/* Header Section */}
      <div className="px-4 md:px-16 mt-8 mb-4">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="mb-4 md:mb-0 text-center md:text-left">
            <h3 className="text-xl md:text-2xl text-[#F9B401] mb-2">Products</h3>
            <h1 className="text-3xl md:text-5xl font-bold">Deals of the day</h1>
          </div>
          {/* Countdown Timer */}
          <div className="text-lg md:text-xl bg-[#F9B401] text-white py-2 px-4 rounded-lg font-semibold shadow-md">
            {formatTime(timeLeft.days)} Days {formatTime(timeLeft.hours)} : {formatTime(timeLeft.minutes)} : {formatTime(timeLeft.seconds)}
          </div>
        </div>
      </div>

      {/* Product Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8 md:px-16 px-4 mb-8">
        {products.map((product, index) => (
          <div
            key={index}
            className="bg-white shadow-lg rounded-lg overflow-hidden relative p-4 hover:shadow-xl transition-shadow duration-300 ease-in-out"
          >
            {/* Product Image */}
            <div className="relative w-full">
              <div className="w-full bg-[#f6f6f6] rounded-lg overflow-hidden">
                <img
                  src={product.image}
                  alt={product.name}
                  className="w-full h-64 md:h-80 object-contain transform transition-transform duration-300 hover:scale-105"
                />
              </div>

              {/* Volume Badge */}
              <div className="absolute top-2 right-2 bg-[#e38b8b] text-white text-xs font-semibold px-2 py-1 rounded-lg">
                {product.volume}
              </div>
            </div>

            {/* Product Details */}
            <div className="pt-4 text-center">
              {/* Star Rating */}
              <div className="flex justify-center items-center space-x-1 mb-2">
                {[...Array(5)].map((_, i) => (
                  <FaStar key={i} size={14} className={i < product.rating ? 'text-[#F5A93F]' : 'text-gray-300'} />
                ))}
              </div>

              {/* Product Name */}
              <h2 className="text-md tracking-wide font-semibold mb-2">
                {product.name}
              </h2>

              {/* Product Price */}
              <p className="text-lg font-semibold text-[#F9B401]">
                {product.price}
              </p>
            </div>
          </div>
        ))}
      </div>
    </React.Fragment>
  );
};

export default ProductCards;
