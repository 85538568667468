import React from 'react';
import Newsletter from '../../Components/Contact/Newsletter';
import DropUsLine from '../../Components/Contact/DropUsLine';
import Map from '../../Components/Contact/Map';
import SimpleBanner from '../Common/SimpleBanner'
import Contactbanar from '../../Assets/ProductCard/ContactBanner.png'
const Contact = () => {
  return (
    <React.Fragment>
       <SimpleBanner
        image={Contactbanar}
        name="Contact"
        path="Home / Contact"
      />
      <Map />
      <DropUsLine />
      {/* <Newsletter /> */}
    </React.Fragment>
  )
}
export default Contact