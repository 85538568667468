import React from 'react';

const QuoteCard = () => {
    return (
        <div className="p-6 md:p-10 rounded-lg text-center">
            <p className="text-yellow-600 text-lg font-semibold mb-4">Who We Are</p>
            <p className="text-gray-800 text-xl font-light italic leading-relaxed">
                "We have been bottling freshness for over 20 years and still have the same passion for it
                just as when we first started. The idea is not just about quenching the thirst, Baisaly aims
                to provide customers with a beverage that refreshes them to the core."
            </p>
            <div className="mt-6">
                <p className="font-signature text-2xl">Sudhir Jain</p>
                <p className="text-gray-500 font-semibold mt-1">
                    Sudhir Jain - <span className="text-yellow-600">Founder</span>
                </p>
            </div>
        </div>
    );
};

export default QuoteCard;