import React from 'react';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from 'react-icons/fa';
import { TbMail } from "react-icons/tb";
import { MdOutlineLocalPhone } from "react-icons/md";

const TopNav = () => {
    return (
        <nav className="hidden md:block bg-[#191C1F] text-white px-6 md:px-16 py-2">
            <div className="container mx-auto flex items-center justify-between relative">
                {/* 1st Section - Phone and Email */}
                <div className="flex items-center space-x-4">
                    <div className="flex items-center space-x-1">
                        <MdOutlineLocalPhone size={22} color='#F9CE06' />
                        <span className='text-sm tracking-wide'>
                            +0670-231347, 9861099936
                        </span>
                    </div>
                    <div className="flex items-center space-x-2">
                        <TbMail size={23} color='#F9CE06' />
                        <span className='text-sm tracking-wide'>
                            baisalyaqua@gmail.com
                        </span>
                    </div>
                </div>

                {/* 2nd Section - Center Text */}
                <div
                    style={{ 'fontFamily': 'cursive' }}
                    className="absolute left-1/2 transform -translate-x-1/2 text-center space-x-2 tracking-wider">
                    <span className="text-sm font-semibold">
                        Exclusive
                    </span>

                    <span
                        className="text-xl md:text-2xl font-extrabold text-[#F9CE06]">
                        Special
                    </span>

                    <span className="text-sm font-semibold">
                        Offer
                    </span>
                </div>

                {/* 3rd Section - Social Media Icons */}
                <div className="flex space-x-4">
                    <div className="text-[#F9CE06]">
                        <FaFacebookF />
                    </div>
                    <div className="text-[#F9CE06]">
                        <FaTwitter />
                    </div>
                    <div className="text-[#F9CE06]">
                        <FaInstagram />
                    </div>
                    <div className="text-[#F9CE06]">
                        <FaLinkedinIn />
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default TopNav;
